@renner-path: '@{sol-font-path}/renner';
@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,400i,500,700|Open+Sans:300,400,400i,600,700&display=swap');
@font-face {
  font-family: 'Renner';
  src: url('@{renner-path}/renner-light.woff2') format('woff2'),
       url('@{renner-path}/renner-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Renner';
  src: url('@{renner-path}/renner-book.woff2') format('woff2'),
       url('@{renner-path}/renner-book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Renner';
  src: url('@{renner-path}/renner-bookitalic.woff2') format('woff2'),
       url('@{renner-path}/renner-bookitalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Renner';
  src: url('@{renner-path}/renner-medium.woff2') format('woff2'),
       url('@{renner-path}/renner-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Renner';
  src: url('@{renner-path}/renner-bold.woff2') format('woff2'),
       url('@{renner-path}/renner-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
