header .sol-top .sol-top-logo > img {
  height: 70px;
  margin-top: -8px;
}
.sol-popular-pages-container {
  background-color: @rt-blue;
  .sol-popular-pages {
    & > a {
      align-items: center;
    }
  }
}
