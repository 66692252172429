.sol-startpage-wrapper .sol-startpage-content-bottom > .sv-layout:last-child {
  display: block;
}
.sol-startpage-content-bottom {
  &__blurb {
    >.sv-vertical {
      border-top: 6px solid @rt-blue;
    }
  }
}
